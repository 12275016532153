// Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <p className="footer-para">
              {/* Add footer content here */}
            </p>
          </div>
          <div className="col-md-4">
            <div className="payment-icons">
              <img src="images/visa.png" alt="Visa" />
              <img src="images/master_card.png" alt="MasterCard" />
              <img src="images/mada.png" alt="Mada" />
            </div>
            <div className="list-links">
              <a href="https://rezqcapital.sa/privacy-policy" className="link-route">Privacy Policy</a>
            </div>
            {/* Other footer links */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
