import React, { useState, useEffect } from 'react';
import { getRequest } from '../axios_request';

const StartAssessment = () => {
  const [assessmentData, setAssessmentData] = useState(null); // State to store API response
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null); // State to track errors
  const [percentage, setPercentage] = useState(null); // State to store the percentage
  const [totalNumbers, settotalNumbers] = useState(null); // State to store the percentage
  const [totalNumbersLabel, settotalNumbersLabel] = useState(null); // State to store the percentage

  // Fetch data from API when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequest('/getAssessmentData'); // Fetch data from API
        setAssessmentData(response.data); // Store the data in state
        setLoading(false); // Set loading to false after data is fetched

        // Calculate the sum of values and the percentage
        const sumOfValues = response.data.reduce((acc, item) => acc + item.value, 0);
        settotalNumbers(sumOfValues); // Store the calculated percentage

        if (sumOfValues >= 10 && sumOfValues <= 19) {
          settotalNumbersLabel('<div>Risk Level: Low</div>')
        } else if (sumOfValues >= 20 && sumOfValues <= 29) {
          settotalNumbersLabel('<div>Risk Level: Medium</div>')
        } else if (sumOfValues >= 30 && sumOfValues <= 39) {
          settotalNumbersLabel('<div>Risk Level: High</div>')
        } else if (sumOfValues >= 40 && sumOfValues <= 50) {
          settotalNumbersLabel('<div>Risk Level: Very High</div>')
        } else { }
        const calculatedPercentage = (sumOfValues / 50) * 100; // Divide by 50 and multiply by 100
        setPercentage(calculatedPercentage); // Store the calculated percentage
      } catch (err) {
        setError(err.message); // Store error if API request fails
        setLoading(false); // Set loading to false even if error occurs
      }
    };

    fetchData(); // Call the function to fetch data
  }, []); // Empty dependency array means this runs only once when the component mounts

  if (loading) {
    return <div>Loading...</div>; // Display loading message until data is fetched
  }

  if (error) {
    return <div>Error: {error}</div>; // Display error if there's an issue with the API request
  }

  // Check if assessmentData and assessmentData.data are available before rendering
  return (
    <div className="start-assessment">



      <div className="row w-100 container mt-5 mb-5 m-auto">
        <div className="ms-3">
          <h3 className="mb-0 h4 font-weight-bolder mb-3">Assessment Result</h3>
          <div className="col-12">
                  <div className="card">
                    <div className="card-header">Your Assessment Details</div>
                    <div className="card-body">
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        {assessmentData?.map((item) => {
                          // Capitalize the first letter of each key_name
                          const capitalizedKeyName = item.key_name.charAt(0).toUpperCase() + item.key_name.slice(1);

                          return (
                            <div key={item.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <strong>{capitalizedKeyName}:</strong>
                              <span>{item.value} Points</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
        </div>
      </div>
    </div>
  );
};

export default StartAssessment;
