import React, { useState, useEffect } from 'react';

const Wallets = () => {
  const [user, setuser] = useState('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setuser(user);
    }
  }, []);

  return (
    <div className="wallets-container">
      <h1 className="h4 mb-4">Choose Wallet</h1>

      <div className="row row-cols-1 row-cols-md-3 g-4">

        <div className="col">
          <div className="card shadow-sm border-light rounded">
            <div className="card-body text-center">
              <h5 className="card-title">Body</h5>
              <p className="card-text">Market Value: 3,642.66 SAR</p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card shadow-sm border-light rounded">
            <div className="card-body text-center">
              <h5 className="card-title">Ahmed</h5>
              <p className="card-text">Market Value: 407,291.98 SAR</p>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card shadow-sm border-light rounded">
            <div className="card-body text-center">
              <h5 className="card-title">Kinda</h5>
              <p className="card-text">Market Value: 12,975.75 SAR</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-3 g-4 mt-3">
        <div className="col-md-12">
          <div className="card shadow-sm border-light rounded">
            <div className="card-body text-center">
              <h5 className="card-title">All Wallets</h5>
              <p className="card-text">Wallet Balance: 426,755.47 SAR</p>
              <p className="card-text">Invested: 355,162.49 SAR</p>
              <p className="card-text">Market Value: 426,225.39 SAR</p>
              <p className="card-text profit">Profit/Loss: 71,062.91 SAR</p>
            </div>
          </div>
        </div>

      </div>
      <div className="row bg-light row-cols-1 row-cols-md-3 g-4 mt-3 m-1">
        <div className="col-md-8">
          <h2>Deposit Information (Ahmed)</h2>

         <p>Deposit to your wallet using bank transfer with the IBAN data below.</p>

          <button type="button" className="btn btn-primary">Make permanent order</button>

          <p className="mt-3"><strong>The deposit process takes from 5 minutes to 2 business days, depending on the bank from which the transfer was made from.</strong></p>
        </div>

        <div className="col-md-4">
          <div className="card bg-dark text-white">
            <div className="card-body">
              <h5 className="card-title">Name of the Recipient</h5>
              <p className="card-text">{user.name}</p>
              <p className="card-text">Account Number: {user.iban_number}</p>
              <p className="card-text">IBAN Number: {user.account_number}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallets;
