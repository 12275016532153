// Navbar.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem('user');
  const handleLogout = () => {
    window.Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out of your account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes, log out',
      cancelButtonText: 'No, stay logged in',
      dangerMode: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem('user');
          navigate('/login');
        }
      });
  };

  return (
    <>
      <nav class="navbar navbar-main navbar-expand-lg px-0 mx-3 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
        <div class="container-fluid py-1 px-3">
          <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar" style={{ 'justify-content': 'flex-end' }}>
            <ul class="navbar-nav d-flex align-items-center justify-content-end" style={{ 'gap': '20px' }}>
              <li class="nav-item d-flex align-items-center">
                <a
                  href="#"
                  className="nav-link text-body font-weight-bold px-0"
                  onClick={handleLogout}
                >
                  <i class="fa fa-power-off"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
