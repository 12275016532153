import React, { useState } from 'react';

const StartAssessment = () => {
  return (
    <div className="">
      <div className="row w-100">
        <div className="ms-3">
          <h3 className="mb-0 h4 font-weight-bolder">Moderately Conservative</h3>
          <p className="mb-4">
            Monitor the sales, value, and bounce rate with a Moderately Conservative approach by country.
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f8f9fa",
              border: "1px solid #e9ecef",
              borderRadius: "8px",
              padding: "16px",
              width: "100%",
            }}
          >
            {/* Icon Section */}
            <div
              style={{
                backgroundColor: "#eef3fa",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50px",
                height: "50px",
                marginRight: "16px",
              }}
            >
              <img
                src="https://thumbs.dreamstime.com/b/conversation-concept-icon-design-illustration-123928956.jpg"
                alt="Icon"
                style={{ width: "50px", height: "50px", borderRadius: "50%", }}
              />
            </div>

            {/* Content Section */}
            <div style={{ flexGrow: 1 }}>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  marginBottom: "4px",
                }}
              >
                Moderately Conservative
              </div>
              <div
                style={{
                  fontSize: "14px",
                  color: "#6c757d",
                }}
              >
                Risk Level (Medium):
              </div>
              {/* Progress Bar */}
              <div
                style={{
                  height: "8px",
                  backgroundColor: "#e9ecef",
                  borderRadius: "4px",
                  marginTop: "4px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    width: "40%",
                    height: "100%",
                    backgroundColor: "#0d6efd",
                  }}
                ></div>
              </div>
            </div>
          </div>


          <div className="row g-4 mt-3">
            {/* Left Column */}
            <div className="col-lg-4">
              {/* Portfolio Overview */}
              

              {/* Asset Allocation */}
              <div className="card">
                <div className="card-body text-center">
                  <h5>Asset Allocation</h5>
                  <div
                    style={{
                      width: "150px",
                      height: "150px",
                      margin: "auto",
                      background: "conic-gradient(#0d6efd 40%, #ffc107 50%, #dc3545 75%, #198754 100%)",
                      borderRadius: "50%",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        background: "#fff",
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                      }}
                    >
                      40%
                    </div>
                  </div>
                  <p className="mt-3">Stocks (40%)</p>
                  <div>
                    <span style={{ color: "#0d6efd" }}>● Stocks</span>{" "}
                    <span style={{ color: "#ffc107" }}>● Gold</span>{" "}
                    <span style={{ color: "#dc3545" }}>● Real Estate</span>{" "}
                    <span style={{ color: "#198754" }}>● Sukuk</span>
                  </div>
                </div>
              </div>

              {/* List of Allocations */}
              <div className="mt-4">
                {[
                  { name: "US Stocks (SPUS)", percentage: 30 },
                  { name: "Developed Countries Stocks (ISDWL)", percentage: 5 },
                  { name: "Emerging Markets Stocks (ISEL)", percentage: 5 },
                  { name: "Sukuk (SPSK)", percentage: 40 },
                  { name: "Gold (GLD)", percentage: 10 },
                  { name: "Real Estate (SPRE)", percentage: 10 },
                ].map((item, index) => (
                  <div key={index} className="d-flex justify-content-between align-items-center border-bottom py-2">
                    <span>{item.name}</span>
                    <span>{item.percentage}%</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Right Column */}
            <div className="col-lg-8">
              <div className="row g-4">
                {/* Historical Performance */}
                <div className="col-12">
                <div className="card">
                <div className="card-body">
                  <p>
                    Portfolio's investments are concentrated in Sukuk to mitigate
                    the risk of the portfolio, but also include investments in the
                    US stock market, real estate, and gold with carefully selected
                    weights that achieve growth and protect against inflation.
                  </p>
                  <p>
                    <strong>Average Return:</strong> 6% - 8%
                  </p>
                  <p>
                    <strong>Average Standard Deviation:</strong> 7.06%
                  </p>
                </div>
              </div>

                </div>
                

                {/* Top Companies */}
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">Portfolio's Top Companies</div>
                    <div className="card-body">
                      <p>
                        This portfolio covers 750+ companies, 17 sectors, Sukuk,
                        real estate, and gold.
                      </p>
                      <div className="d-flex flex-wrap gap-2">
                        <img style={{ width: "50px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Tesla_logo.png/1024px-Tesla_logo.png" alt="Company Logo" />
                        <img style={{ width: "50px" }} src="https://mlt.org/wp-content/uploads/2018/04/logo-1.png" alt="Company Logo" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Portfolio Coverage */}
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">Portfolio Coverage</div>
                    <div className="card-body">
                      <div
                        style={{
                          width: "100%",
                          height: "400px",
                          background: "#e9ecef",
                          borderRadius: "20px",
                        }}
                      >

                        <img
                          src="https://static.vecteezy.com/system/resources/previews/020/833/849/non_2x/world-map-with-country-names-free-vector.jpg"
                          style={{ width: "100%", height: "100%", borderRadius: "20px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



















      </div>
    </div>
  );
};

export default StartAssessment;
