import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();

  // Check if the user is logged in by looking for user data in localStorage
  const user = localStorage.getItem('user');

  const handleLogout = () => {
    // Show SweetAlert confirmation before logout
    window.Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out of your account!",
      icon: "warning",
      showCancelButton: true,  // Use showCancelButton for SweetAlert2
      confirmButtonText: 'Yes, log out',
      cancelButtonText: 'No, stay logged in',
      dangerMode: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          // If user confirms, remove the user from localStorage and navigate to login
          localStorage.removeItem('user');
          navigate('/login');
        }
      });
  };

  return (
    <header className="header">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <Link className="navbar-brand" to="/">
            <img src="images/logo.jpeg" className="main-logo" alt="logo" />
          </Link>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav left-nav">
              {/* Conditionally render Login/Get Started or Logout link */}
              {!user ? (
                <>
                  <li>
                    <Link className="nav-link btn-login-1" to="/login">
                      Login
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link btn-login ms-10px" to="/signup">
                      Get Started
                    </Link>
                  </li>
                </>
              ) : (
                <>
                 <li>
                    <Link className="nav-link btn-login-1" to="/dashboard">
                      Details
                    </Link>
                  </li>
                 <li>
                    <Link className="nav-link btn-login-1" to="/start-assessment">
                      Assessment
                    </Link>
                  </li>

                  <li>
                    {/* Use a Link with an anchor tag for logout */}
                    <a
                      href="#"
                      className="nav-link btn-login ms-10px"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </li>
                 
                </>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
