import React, { Component } from 'react';
import { getRequest } from '../axios_request';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';

class StartAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assessmentData: null,  // State to store API response
      loading: true,         // State to track loading status
      error: null,           // State to track errors
      percentage: null,      // State to store the percentage
      totalNumbers: null,    // State to store the total numbers
      totalNumbersLabel: null,  // State to store the risk label
      usStocks: 0,          // State for US Stocks
      developedStocks: 0,    // State for Developed Countries Stocks
      emergingStocks: 0,     // State for Emerging Markets Stocks
      chartData: {           // State to store chart data
        labels: ['Stocks'],
        datasets: [
          {
            label: 'Stock Allocation',
            data: [0, 0, 0],  // Initial data for the stocks
            borderColor: '#0d6efd',
            backgroundColor: 'rgba(13, 110, 253, 0.2)',
            tension: 0.4,
            fill: true,
          },
        ],
      },
    };
  }

  // Fetch data from API when component mounts
  componentDidMount() {
    const fetchData = async () => {
      try {
        const response = await getRequest('/getAssessmentData'); // Fetch data from API
        this.setState({
          assessmentData: response.data,
          loading: false, // Set loading to false after data is fetched
        });

        // Calculate the sum of values and the percentage
        const sumOfValues = response.data.reduce((acc, item) => acc + item.value, 0);
        this.setState({ totalNumbers: sumOfValues });

        if (sumOfValues >= 10 && sumOfValues <= 19) {
          this.setState({ totalNumbersLabel: '<div>Risk Level: Low</div>' });
        } else if (sumOfValues >= 20 && sumOfValues <= 29) {
          this.setState({ totalNumbersLabel: '<div>Risk Level: Medium</div>' });
        } else if (sumOfValues >= 30 && sumOfValues <= 39) {
          this.setState({ totalNumbersLabel: '<div>Risk Level: High</div>' });
        } else if (sumOfValues >= 40 && sumOfValues <= 50) {
          this.setState({ totalNumbersLabel: '<div>Risk Level: Very High</div>' });
        }

      } catch (err) {
        this.setState({
          error: err.message,
          loading: false, // Set loading to false even if error occurs
        });
      }
    };

    fetchData(); // Call the function to fetch data
  }

  // Function to calculate percentage based on stock values
  calculatePercentage = () => {
    const { usStocks, developedStocks, emergingStocks } = this.state;
    const totalStockValue = usStocks + developedStocks + emergingStocks;
    const calculatedPercentage = (totalStockValue / 100) * 100; // Adjust this logic as needed
    this.setState({ percentage: calculatedPercentage });

    // Update chart data based on new stock values
    const updatedChartData = { ...this.state.chartData };
    updatedChartData.datasets[0].data = [usStocks, developedStocks, emergingStocks];
    this.setState({ chartData: updatedChartData });
  };

  // Handle keyup events for stock values
  handleStockChange = (type, value) => {
    this.setState(
      { [type]: value },
      () => {
        this.calculatePercentage();
      }
    );
  };

  render() {
    const {
      loading, error, percentage, totalNumbersLabel, assessmentData, usStocks, developedStocks, emergingStocks, chartData,
    } = this.state;

    if (loading) {
      return <div>Loading...</div>; // Display loading message until data is fetched
    }

    if (error) {
      return <div>Error: {error}</div>; // Display error if there's an issue with the API request
    }

    return (
      <div className="start-assessment">
        <div className="row w-100">
          <div className="ms-3">
            <h3 className="mb-0 h4 font-weight-bolder">Customized</h3>
            <p className="mb-4">
              Monitor the sales, value, and bounce rate with a Customized approach by country.
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#f8f9fa",
                border: "1px solid #e9ecef",
                borderRadius: "8px",
                padding: "16px",
                width: "100%",
              }}
            >
              {/* Icon Section */}
              <div
                style={{
                  backgroundColor: "#eef3fa",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "50px",
                  height: "50px",
                  marginRight: "16px",
                }}
              >
                <img
                  src="https://thumbs.dreamstime.com/b/conversation-concept-icon-design-illustration-123928956.jpg"
                  alt="Icon"
                  style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                />
              </div>

              {/* Content Section */}
              <div style={{ flexGrow: 1 }}>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    marginBottom: "4px",
                  }}
                >
                  Customized
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#6c757d",
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: totalNumbersLabel }} />
                </div>

                {/* Progress Bar */}
                <div
                  style={{
                    height: "8px",
                    backgroundColor: "#e9ecef",
                    borderRadius: "4px",
                    marginTop: "4px",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      width: percentage ? percentage.toFixed(2) + "%" : "0%",
                      height: "100%",
                      backgroundColor: "#0d6efd",
                    }}
                  ></div>
                </div>
              </div>
            </div>

            <div className="row g-4 mt-3">
              {/* Left Column */}
              <div className="col-lg-4">
                {/* Portfolio Overview */}
                <div className="card">
                  <div className="card-body text-center">
                    <h5>Asset Allocation</h5>
                    <div
                      style={{
                        width: "150px",
                        height: "150px",
                        margin: "auto",
                        background: `conic-gradient(#0d6efd ${percentage ? percentage.toFixed(2) : 0}%, #ffc107 50%, #dc3545 75%, #198754 100%)`,
                        borderRadius: "50%",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          background: "#fff",
                          width: "70px",
                          height: "70px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                        }}
                      >
                        {percentage ? percentage.toFixed(2) : '0'}%
                      </div>
                    </div>
                    <p className="mt-3">Percentage ({percentage ? percentage.toFixed(2) : '0'}%)</p>
                  </div>
                </div>

                {/* List of Allocations */}
                <div className="mt-4">
                  {[
                    { name: "US Stocks (SPUS)", value: usStocks },
                    { name: "Developed Countries Stocks (ISDWL)", value: developedStocks },
                    { name: "Emerging Markets Stocks (ISEL)", value: emergingStocks },
                    { name: "Sukuk (SPSK)", value: 70 },
                    { name: "Gold (GLD)", value: 3 },
                    { name: "Real Estate (SPRE)", value: 2 },
                  ].map((item, index) => (
                    <div key={index} className="d-flex justify-content-between align-items-center border-bottom py-2">
                      <span>{item.name}</span>
                      <input
                        className="form-control custom-input"
                        type="number"
                        value={item.value}
                        onChange={(e) => this.handleStockChange(item.name.split(' ')[0].toLowerCase() + 'Stocks', Number(e.target.value))}
                      />
                      {/* <span>{item.value}%</span> */}
                    </div>
                  ))}
                </div>
              </div>

              {/* Right Column */}
              <div className="col-lg-8">
              <div className="row g-4">
                {/* Historical Performance */}
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <p>
                        Portfolio's investments are concentrated in Sukuk to mitigate
                        the risk of the portfolio, but also include investments in the
                        US stock market, real estate, and gold with carefully selected
                        weights that achieve growth and protect against inflation.
                      </p>
                      {/* <p>
                        <strong>Average Return:</strong> 10% - 12%
                      </p>
                      <p>
                        <strong>Average Standard Deviation:</strong> 13.35%
                      </p> */}
                    </div>
                  </div>

                </div>

                {/* Top Companies */}
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">Portfolio's Top Companies</div>
                    <div className="card-body">
                      <p>
                        This portfolio covers 750+ companies, 17 sectors, Sukuk,
                        real estate, and gold.
                      </p>
                      <div className="d-flex flex-wrap gap-2">
                        <img style={{ width: "50px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Tesla_logo.png/1024px-Tesla_logo.png" alt="Company Logo" />
                        <img style={{ width: "50px" }} src="https://mlt.org/wp-content/uploads/2018/04/logo-1.png" alt="Company Logo" />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Portfolio Coverage */}
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">Portfolio Coverage</div>
                    <div className="card-body">
                      <div
                        style={{
                          width: "100%",
                          height: "400px",
                          background: "#e9ecef",
                          borderRadius: "20px",
                        }}
                      >

                        <img
                          src="https://static.vecteezy.com/system/resources/previews/020/833/849/non_2x/world-map-with-country-names-free-vector.jpg"
                          style={{ width: "100%", height: "100%", borderRadius: "20px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StartAssessment;
