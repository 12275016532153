import React from 'react';

const ComingSoon = () => {
  return (
    <div className=" d-flex mt-7 justify-content-center vh-100">
              <h1 className="display-4 text-primary mt-7">We're Coming Soon!</h1>
    </div>
  );
};

export default ComingSoon;
