import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const Layout = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation(); // Get the current route

  // Check if the current route is 'login' or 'signup'
  const isAuthRoute = location.pathname === '/login' || location.pathname === '/signup';

  useEffect(() => {
    const resources = [
      {
        href: 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.2.3/css/bootstrap.rtl.min.css',
        rel: 'stylesheet',
      },
      {
        href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css',
        rel: 'stylesheet',
      },
      {
        href: 'https://cdnjs.cloudflare.com/ajax/libs/sweetalert2/11.15.2/sweetalert2.css',
        rel: 'stylesheet',
      },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/sweetalert2/11.15.2/sweetalert2.min.js',
        type: 'text/javascript',
      },
      {
        src: 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js',
        type: 'text/javascript',
      },
      {
        href: '/admin_assets/css/material-dashboard.css?v=3.2.0',
        rel: 'stylesheet',
      },
      {
        href: '/assets/style.css',
        rel: 'stylesheet',
      },
    ];

    let loadedCount = 0;
    const totalResources = resources.length;

    const handleLoad = () => {
      loadedCount += 1;

      if (loadedCount === totalResources) {
        setIsLoaded(true); // All resources have been loaded
      }
    };

    // Add CSS and JS files dynamically
    resources.forEach((resource) => {
      if (resource.rel === 'stylesheet') {
        const linkElement = document.createElement('link');
        linkElement.rel = resource.rel;
        linkElement.href = resource.href;
        linkElement.onload = handleLoad;
        linkElement.onerror = handleLoad; // Even if a resource fails, count it as loaded
        document.head.appendChild(linkElement);
      } else if (resource.type === 'text/javascript') {
        const scriptElement = document.createElement('script');
        scriptElement.src = resource.src;
        scriptElement.type = resource.type;
        scriptElement.onload = handleLoad;
        scriptElement.onerror = handleLoad; // Even if a resource fails, count it as loaded
        document.head.appendChild(scriptElement);
      }
    });

    // Cleanup resources on component unmount
    return () => {
      resources.forEach((resource) => {
        if (resource.rel === 'stylesheet') {
          const link = document.querySelector(`link[href="${resource.href}"]`);
          if (link) link.remove();
        } else if (resource.type === 'text/javascript') {
          const script = document.querySelector(`script[src="${resource.src}"]`);
          if (script) script.remove();
        }
      });
    };
  }, []);

  if (!isLoaded) {
    return (
      <div style={{ background: '#fff', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      {!isAuthRoute && <Header />}
      <main>{children}</main>
      {!isAuthRoute && <Footer />}
    </div>
  );
};

export default Layout;
