import React, { useState } from 'react';
import { postRequest } from '../axios_request'; // Adjust the import path based on where the file is
import { Link, useNavigate } from 'react-router-dom';

const StartAssessment = () => {

  const navigate = useNavigate();

  const [answers, setAnswers] = useState({
    employment: '',
    regularIncome: ''
    // Include all other fields here
  });

  const handleChange = (e) => {
    setAnswers({
      ...answers,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    const formattedData = Object.keys(answers).reduce((acc, key) => {
      acc[key] = parseInt(answers[key]);  // Assuming values are numeric, adjust as needed
      return acc;
    }, {});

    try {
      const response = await postRequest('/postAssessment', { assessmentData: formattedData });

      // Using SweetAlert2 for the success message and then redirecting after close
      window.Swal.fire({
        title: 'Success!',
        text: 'Assessment submitted successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        // Redirect after the user clicks 'OK'
        navigate('/assessment-result');
      });

    } catch (error) {
      console.error('Error during submission:', error);

      // Using SweetAlert2 for the error message
      window.Swal.fire({
        title: 'Error!',
        text: 'An error occurred. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }

  };

  return (
    <div className="container mt-5">
      <h2>Investment Assessment</h2>

      <hr />
      <form onSubmit={handleSubmit}>
        <div className="row">




          <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4 assess-993">
            <h5>What is your current employment status?</h5>
            <div className="form-check">
              <input
                required type="radio"
                name="employment"
                value="1"
                id="employment1"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="employment1">
                Unemployed
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="employment"
                value="2"
                id="employment2"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="employment2">
                Employed part-time 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="employment"
                value="3"
                id="employment3"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="employment3">
                Employed full-time
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="employment"
                value="4"
                id="employment4"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="employment4">
                Retired
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="employment"
                value="5"
                id="employment5"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="employment5">
                Self-employed 
              </label>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4 assess-993">
            <h5>What is your highest completed education level?</h5>
            <div className="form-check">
              <input
                required type="radio"
                name="education"
                value="1"
                id="education1"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="education1">
                High school 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="education"
                value="2"
                id="education2"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="education2">
                College/Technical diploma 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="education"
                value="3"
                id="education3"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="education3">
                Bachelors degree 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="education"
                value="4"
                id="education4"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="education4">
                Masters degree 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="education"
                value="5"
                id="education5"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="education5">
                Doctorate degree 
              </label>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4 assess-993">
            <h5>What is your approximate annual income?</h5>
            <div className="form-check">
              <input
                required type="radio"
                name="income"
                value="1"
                id="income1"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="income1">
                Under SAR 25,000 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="income"
                value="2"
                id="income2"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="income2">
                SAR 25,000 - SAR 50,000 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="income"
                value="3"
                id="income3"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="income3">
                SAR 50,000 - SAR 200,000 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="income"
                value="4"
                id="income4"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="income4">
                SAR 200,000 - SAR 500,000 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="income"
                value="5"
                id="income5"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="income5">
                Over SAR 500,000 
              </label>
            </div>
          </div>


          <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4 assess-993">
            <h5>Do you own real estate property?</h5>
            <div className="form-check">
              <input
                required type="radio"
                name="realEstate"
                value="1"
                id="realEstate1"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="realEstate1">
                No real estate owned 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="realEstate"
                value="2"
                id="realEstate2"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="realEstate2">
                Primary residence only 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="realEstate"
                value="3"
                id="realEstate3"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="realEstate3">
                Investment property/second home 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="realEstate"
                value="4"
                id="realEstate4"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="realEstate4">
                3+ investment properties 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="realEstate"
                value="5"
                id="realEstate5"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="realEstate5">
                Commercial or high value property 
              </label>
            </div>
          </div>








          <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4 assess-993">
            <h5>What is your primary investment goal?</h5>
            <div className="form-check">
              <input
                required type="radio"
                name="investmentGoal"
                value="1"
                id="investmentGoal1"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="investmentGoal1">
                Preserve capital 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="investmentGoal"
                value="2"
                id="investmentGoal2"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="investmentGoal2">
                Generate stable income 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="investmentGoal"
                value="3"
                id="investmentGoal3"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="investmentGoal3">
                Steady growth over time 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="investmentGoal"
                value="4"
                id="investmentGoal4"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="investmentGoal4">
                Aggressive growth potential 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="investmentGoal"
                value="5"
                id="investmentGoal5"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="investmentGoal5">
                Maximum returns 
              </label>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4 assess-993">
            <h5>How long is your planned investment horizon?</h5>
            <div className="form-check">
              <input
                required type="radio"
                name="investmentHorizon"
                value="1"
                id="investmentHorizon1"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="investmentHorizon1">
                &lt; 1 year 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="investmentHorizon"
                value="2"
                id="investmentHorizon2"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="investmentHorizon2">
                1-3 years 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="investmentHorizon"
                value="3"
                id="investmentHorizon3"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="investmentHorizon3">
                4-7 years 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="investmentHorizon"
                value="4"
                id="investmentHorizon4"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="investmentHorizon4">
                8-10 years 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="investmentHorizon"
                value="5"
                id="investmentHorizon5"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="investmentHorizon5">
                &gt; 10 years 
              </label>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4 assess-993">
            <h5>How would you react to a 15% drop in your portfolio?</h5>
            <div className="form-check">
              <input
                required type="radio"
                name="portfolioDrop"
                value="1"
                id="portfolioDrop1"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="portfolioDrop1">
                Panic and sell 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="portfolioDrop"
                value="2"
                id="portfolioDrop2"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="portfolioDrop2">
                Uncomfortable but hold 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="portfolioDrop"
                value="3"
                id="portfolioDrop3"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="portfolioDrop3">
                Tolerable if temporary 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="portfolioDrop"
                value="4"
                id="portfolioDrop4"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="portfolioDrop4">
                Acceptable fluctuation 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="portfolioDrop"
                value="5"
                id="portfolioDrop5"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="portfolioDrop5">
                Opportunity to increase position 
              </label>
            </div>
          </div>














          <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4 assess-993">
            <h5>What is your preferred portfolio allocation?</h5>
            <div className="form-check">
              <input
                required type="radio"
                name="portfolioAllocation"
                value="1"
                id="portfolioAllocation1"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="portfolioAllocation1">
                100% stable cash/bonds 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="portfolioAllocation"
                value="2"
                id="portfolioAllocation2"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="portfolioAllocation2">
                60% low-risk, 40% medium-risk 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="portfolioAllocation"
                value="3"
                id="portfolioAllocation3"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="portfolioAllocation3">
                40% low-risk, 40% medium-risk, 20% high-risk 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="portfolioAllocation"
                value="4"
                id="portfolioAllocation4"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="portfolioAllocation4">
                20% low-risk, 60% medium-risk, 20% high-risk 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="portfolioAllocation"
                value="5"
                id="portfolioAllocation5"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="portfolioAllocation5">
                100% stocks/alternative assets 
              </label>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4 assess-993">
            <h5>How much of a portfolio loss are you willing to tolerate?</h5>
            <div className="form-check">
              <input
                required type="radio"
                name="portfolioLossTolerance"
                value="1"
                id="portfolioLossTolerance1"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="portfolioLossTolerance1">
                0-5% loss 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="portfolioLossTolerance"
                value="2"
                id="portfolioLossTolerance2"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="portfolioLossTolerance2">
                5-10% loss 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="portfolioLossTolerance"
                value="3"
                id="portfolioLossTolerance3"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="portfolioLossTolerance3">
                10-20% loss 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="portfolioLossTolerance"
                value="4"
                id="portfolioLossTolerance4"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="portfolioLossTolerance4">
                20-35% loss 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="portfolioLossTolerance"
                value="5"
                id="portfolioLossTolerance5"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="portfolioLossTolerance5">
                &gt; 35% loss 
              </label>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4 assess-993">
            <h5>Do you need regular income from your investments?</h5>
            <div className="form-check">
              <input
                required type="radio"
                name="regularIncome"
                value="1"
                id="regularIncome1"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="regularIncome1">
                Yes, absolutely 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="regularIncome"
                value="2"
                id="regularIncome2"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="regularIncome2">
                Prefer regular income 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="regularIncome"
                value="3"
                id="regularIncome3"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="regularIncome3">
                No strong preference 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="regularIncome"
                value="4"
                id="regularIncome4"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="regularIncome4">
                Prefer reinvesting gains 
              </label>
            </div>
            <div className="form-check">
              <input
                required type="radio"
                name="regularIncome"
                value="5"
                id="regularIncome5"
                className="form-check-input"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="regularIncome5">
                No, all capital gains reinvestment 
              </label>
            </div>
          </div>












        </div>
        <div style={{ textAlign: 'right' }}>
          <button type="submit" className="btn btn-dark mt-3 mb-5">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default StartAssessment;
