// Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';

const Header = () => {
  return (
      <Navbar />
  );
};

export default Header;
