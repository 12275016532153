import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = ({ mobileOpen }) => {
  const [portfolioOpen, setPortfolioOpen] = useState(false);
  const location = useLocation();

  const togglePortfolio = () => {
    setPortfolioOpen(!portfolioOpen);
  };

  let mobileOpenClass = mobileOpen ? 'nav-opened' : 'nav-closed';

  // Function to determine if a route is active
  const isActiveRoute = (path) => location.pathname === path ? 'active-link' : '';

  return (
    <aside className={`sidenav navbar navbar-vertical navbar-expand-xs border-radius-lg fixed-start ms-2 bg-white my-2 ${mobileOpenClass}`} id="sidenav-main">
      <div className="sidenav-header">
        <Link className="navbar-brand px-4 py-3 m-0" to="/">
          <img src="images/logo.jpeg" className="navbar-brand-img" alt="logo" />
        </Link>
      </div>
      <hr className="horizontal dark mt-0 mb-2" />
      <div className="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className={`nav-link text-dark ${isActiveRoute('/dashboard')}`} to="/dashboard">
              <i className="material-symbols-rounded opacity-5">dashboard</i>
              <span className="nav-link-text ms-1">Home</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className={`nav-link text-dark ${isActiveRoute('/wallets')}`} to="/wallets">
              <i className="material-symbols-rounded opacity-5">dashboard</i>
              <span className="nav-link-text ms-1">Your Wallets</span>
            </Link>
          </li>
          {/* Portfolio Parent */}
          <li className="nav-item" style={{ backgroundColor: '#f0f0f0' }}>
            <a 
              className="nav-link text-dark cursor-pointer" 
              onClick={togglePortfolio}
              style={{ color: '#007bff' }}
            >
              <i className="material-symbols-rounded opacity-5">dashboard</i>
              <span className="nav-link-text ms-1">Portfolio</span>
            </a>
            <ul className={`navbar-nav ps-3 ${portfolioOpen ? 'collapse show' : 'collapse show'}`}>
              <li className="nav-item">
                <Link className={`nav-link text-dark ${isActiveRoute('/conservative')}`} to="/conservative">
                  <i className="material-symbols-rounded opacity-5">dashboard</i>
                  <span className="nav-link-text ms-1">Conservative</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link text-dark ${isActiveRoute('/moderately-conservative')}`} to="/moderately-conservative">
                  <i className="material-symbols-rounded opacity-5">dashboard</i>
                  <span className="nav-link-text ms-1">Moderately Conservative</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link text-dark ${isActiveRoute('/moderately-aggressive')}`} to="/moderately-aggressive">
                  <i className="material-symbols-rounded opacity-5">dashboard</i>
                  <span className="nav-link-text ms-1">Moderately Aggressive</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link text-dark ${isActiveRoute('/aggressive')}`} to="/aggressive">
                  <i className="material-symbols-rounded opacity-5">dashboard</i>
                  <span className="nav-link-text ms-1">Aggressive</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link text-dark ${isActiveRoute('/customized')}`} to="/customized">
                  <i className="material-symbols-rounded opacity-5">dashboard</i>
                  <span className="nav-link-text ms-1">Customized</span>
                </Link>
              </li>
            </ul>
          </li>
          {/* Other sections */}
          <li className="nav-item">
            <Link className={`nav-link text-dark ${isActiveRoute('/comming-soon')}`} to="/comming-soon">
              <i className="material-symbols-rounded opacity-5">dashboard</i>
              <span className="nav-link-text ms-1">Transactions</span>
            </Link>
          </li>
          <li className="nav-item">
              <Link className="nav-link text-dark" to="/comming-soon">
                <i className="material-symbols-rounded opacity-5">dashboard</i>
                <span className="nav-link-text ms-1">ETFs Details</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-dark" to="/comming-soon">
                <i className="material-symbols-rounded opacity-5">dashboard</i>
                <span className="nav-link-text ms-1">Deposit Information</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-dark" to="/comming-soon">
                <i className="material-symbols-rounded opacity-5">dashboard</i>
                <span className="nav-link-text ms-1">Calculators</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-dark" to="/comming-soon">
                <i className="material-symbols-rounded opacity-5">dashboard</i>
                <span className="nav-link-text ms-1">Rewards</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-dark" to="/comming-soon">
                <i className="material-symbols-rounded opacity-5">dashboard</i>
                <span className="nav-link-text ms-1">Dependednts List</span>
              </Link>
            </li>

            {/* Payments Parent */}
            <li className="nav-item">
              <Link className="nav-link text-dark" to="/comming-soon">
                <i className="material-symbols-rounded opacity-5">dashboard</i>
                <span className="nav-link-text ms-1">Transfer Wallets</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-dark" to="/comming-soon">
                <i className="material-symbols-rounded opacity-5">dashboard</i>
                <span className="nav-link-text ms-1">Cash Withdraw</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-dark" to="/comming-soon">
                <i className="material-symbols-rounded opacity-5">dashboard</i>
                <span className="nav-link-text ms-1">Bank Information</span>
              </Link>
            </li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
