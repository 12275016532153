import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js modules
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.name) {
      setUserName(user.name);
    }
  }, []);

  // Chart data and options
  const chartData = {
    labels: [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', // Year 1
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'  // Year 2
    ],
    datasets: [
      {
        label: 'Invested Value',
        data: [
          100000, 150000, 200000, 250000, 300000, 350000, 375000, 400000, 425000, 450000, 460000, 470000, // Year 1
          480000, 490000, 500000, 510000, 520000, 530000, 540000, 550000, 560000, 570000, 580000, 590000  // Year 2
        ],
        borderColor: '#007bff',
        backgroundColor: 'rgba(0, 123, 255, 0.2)',
        tension: 0,  // No smoothing, sharp variations
      },
      {
        label: 'Market Value',
        data: [
          100000, 160000, 210000, 260000, 320000, 380000, 390000, 415000, 440000, 460000, 465000, 475000, // Year 1
          485000, 495000, 505000, 515000, 525000, 535000, 545000, 555000, 565000, 575000, 585000, 595000  // Year 2
        ],
        borderColor: '#28a745',
        backgroundColor: 'rgba(40, 167, 69, 0.2)',
        tension: 0,  // No smoothing, sharp variations
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Portfolio Performance',
      },
    },
  };

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="h4">Hello {userName}</h1>
        <button className="btn btn-outline-primary btn-sm">Switch Investment Account</button>
      </div>

      <div className="bg-white rounded shadow-sm p-4 mb-4">
        <h2 className="h5 mb-3">Overview For All Wallets</h2>
        <div className="row text-center">
          <div className="col-6 col-md-3">
            <div className="text-primary fw-bold fs-5">426,755.47 SAR</div>
            <div className="text-muted">Wallets Balance</div>
          </div>
          <div className="col-6 col-md-3">
            <div className="text-primary fw-bold fs-5">355,162.49 SAR</div>
            <div className="text-muted">Invested</div>
          </div>
          <div className="col-6 col-md-3 mt-3 mt-md-0">
            <div className="text-primary fw-bold fs-5">426,225.39 SAR</div>
            <div className="text-muted">Market Value</div>
          </div>
          <div className="col-6 col-md-3 mt-3 mt-md-0">
            <div className="text-success fw-bold fs-5">71,062.91 SAR</div>
            <div className="text-muted">Profit/Loss (20.01%)</div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded shadow-sm p-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2 className="h5 mb-0">Performance (All Wallets)</h2>
          <div>
            <button className="btn btn-outline-secondary btn-sm me-2">Daily</button>
            <button className="btn btn-outline-secondary btn-sm">Date</button>
          </div>
        </div>
        <div style={{ width: '100%', height: 'auto' }}>
          <Line data={chartData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
