// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from './Layouts/Layout'; // Import Layout component
import LayoutAdmin from './Layouts/LayoutAdmin'; // Import Layout component
import Home from './Components/Home';
import Login from './Components/Login';
import Signup from './Components/Signup';
import StartAssessment from './Components/StartAssessment';
import Conservative from './Components/Conservative';
import Customized from './Components/Customized';
import Aggressive from './Components/Aggressive';
import ModeratelyAggressive from './Components/ModeratelyAggressive';
import ModeratelyConservative from './Components/ModeratelyConservative';
import Dashboard from './Components/Dashboard';
import Wallets from './Components/Wallets';
import AssessmentResult from './Components/AssessmentResult';
import CommingSoon from './Components/CommingSoon';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><Home /></Layout>} />
        <Route path="/home" element={<Layout><Home /></Layout>} />
        <Route path="/login" element={<Layout><Login /></Layout>} />
        <Route path="/signup" element={<Layout><Signup /></Layout>} />
        <Route path="/start-assessment" element={<Layout><StartAssessment /></Layout>} />
        <Route path="/conservative" element={<LayoutAdmin><Conservative /></LayoutAdmin>} />
        <Route path="/moderately-conservative" element={<LayoutAdmin><ModeratelyConservative /></LayoutAdmin>} />
        <Route path="/moderately-aggressive" element={<LayoutAdmin><ModeratelyAggressive /></LayoutAdmin>} />
        <Route path="/aggressive" element={<LayoutAdmin><Aggressive /></LayoutAdmin>} />
        <Route path="/customized" element={<LayoutAdmin><Customized /></LayoutAdmin>} />
        <Route path="/dashboard" element={<LayoutAdmin><Dashboard /></LayoutAdmin>} />
        <Route path="/wallets" element={<LayoutAdmin><Wallets /></LayoutAdmin>} />
        <Route path="/assessment-result" element={<Layout><AssessmentResult /></Layout>} />
        <Route path="/comming-soon" element={<Layout><CommingSoon /></Layout>} />

        {/* Add other routes here */}
      </Routes>
    </Router>
  );
}

export default App;
