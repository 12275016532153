import axios from 'axios';

let apiURL;

if (window.location.hostname === 'qa.itmenaan.pk' || window.location.hostname === 'itmenaan.pk') {
  apiURL = 'https://qa.itmenaan.pk/backend/api';
} else {
  apiURL = 'http://localhost:8001/api';
}

const API_BASE_URL = apiURL;
const TIMEOUT = 10000;

// Axios instance with default configuration
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: TIMEOUT,
});

// Request Interceptor to attach user_id as query parameter if present in localStorage
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the user from localStorage on each request
    const user = JSON.parse(localStorage.getItem('user')) || null;
    const userId = user ? user.id : null;

    // Add user_id to the request parameters if available
    if (userId) {
      if (!config.params) {
        config.params = {};
      }
      config.params['user_id'] = userId;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// POST request function
export const postRequest = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

// GET request function
export const getRequest = async (url, params = {}) => {
  try {
    const response = await axiosInstance.get(url, { params });
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};
