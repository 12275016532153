import React, { useState,useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';

import { postRequest } from '../axios_request'; // Adjust the import path based on where the file is

const Home = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate(); 

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      navigate('/dashboard'); 
    }
  }, [navigate]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the form from refreshing the page
    setErrorMessage('');
    setSuccessMessage('');

    if (formData.password !== formData.confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    try {
      const response = await postRequest('/signup', {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        password_confirmation: formData.confirmPassword, // Backend expects this
      });
      setSuccessMessage('Registration successful!');
      console.log('Response:', response);
      setFormData({ name: '', email: '', password: '', confirmPassword: '' });

      navigate('/login?success=true');



      
    } catch (error) {
      console.error('Error:', error.response?.data || error.message);
      if (error.errors) {
        const fieldErrors = error.errors;
        const errorMessages = Object.entries(fieldErrors).map(([field, messages]) => {
          return `${messages.join(', ')}`;
        }).join(' | ');

        setErrorMessage(errorMessages); // Display all error messages
      } else {
        setErrorMessage(error.response?.data?.message || 'Something went wrong. Please try again.');
      }
    }

  };

  return (
    <div>
      <section>
        <div className="page-header min-vh-100">
          <div className="container">
            <div className="row">
              <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 start-0 text-center justify-content-center flex-column">
                <div
                  className="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center"
                  style={{
                    backgroundImage:
                      "url('/admin_assets/img/illustrations/investment-success-depicted-by-a-3d-finance-chart-on-a-blue-coin-backdrop-vertical-mobile-wallpaper-ai-generated-free-photo.jpg')",
                    backgroundSize: 'cover',
                  }}
                ></div>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto me-auto ms-lg-auto me-lg-5">
                <div className="card card-plain">
                  <div className="card-header auth-header-img">
                    <Link className="navbar-brand" to="/">
                      <img src="images/logo.jpeg" className="main-logo" alt="logo" />
                    </Link>
                    <h4 className="font-weight-bolder">Sign Up</h4>
                    <p className="mb-0">Enter your email and password to register</p>
                  </div>
                  <div className="card-body">
                    {errorMessage && (
                      <div style={{ color: 'white' }} className="alert alert-danger">
                        {errorMessage}
                      </div>
                    )}
                    {successMessage && (
                      <div style={{ color: 'white' }} className="alert alert-success">
                        {successMessage}
                      </div>
                    )}
                    <form role="form" onSubmit={handleSubmit}>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label">Name</label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"

                          value={formData.name}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label">Email</label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"

                          value={formData.email}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label">Password</label>
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"

                          value={formData.password}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="input-group input-group-outline mb-3">
                        <label className="form-label">Confirm Password</label>
                        <input
                          type="password"
                          placeholder="Confirm Password"

                          name="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </div>
                    
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-lg bg-gradient-dark btn-lg w-100 mt-4 mb-0"
                        >
                          Sign Up
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="card-footer text-center pt-0 px-lg-2 px-1">
                    <p className="mb-2 text-sm mx-auto">
                      Already have an account?
                      <Link className="text-primary text-gradient font-weight-bold" to="/login">
                        {' '}
                        Sign In
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
