// Home.js
import React from 'react';

const Home = () => {
  return (
    <div>
      <section className="home-hero bg-app-banner">
        <div className="container">
          <div className="row-zakat">
            <div className="col-md-6 sm-100w">
              <div className="content content-padding text-center">
                <h1 className="home-hero-title text-center">Invest with Rezq Capital</h1>
                <h1 className="home-hero-title-md text-center mb-3rem">More Accessible... Simpler... Quicker!</h1>
              </div>
              <div className="download-btn-group">
                <a href="#" target="_blank" rel="noopener noreferrer" className="download-btn">
                  <img id="apple-store" src="images/appleStoreBtn.png" className="download-btn" alt="" />
                </a>
                <a href="#" target="_blank" rel="noopener noreferrer" className="download-btn">
                  <img id="google-play" src="images/googlePlayBtn.png" className="download-btn" alt="" />
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <img src="images/2.jpeg" className="app_banner lg-img" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="about pt-100 pb-200" id="about" data-lang="en">
        <div className="container">
          <div className="row vcenter-item">
            <div className="col-md-6">
              <h2 className="about-title text-left" id="about--t" data-register-btn="Get Started">
                About Rezq Capital
              </h2>
              <p className="about-p">
              Rezq was founded in Saudi Arabia by finance experts seeking to transform how everyday Saudis invest. Our new website makes investing more accessible through easy-to-use tools, educational resources, and personalized guidance. Our platform empowers individuals to access local and global investment opportunities including shares, ETFs, funds, gold, and real estate with ease. We provide a simple and automated approach to building a well-diversified Shariah compliant portfolio. We go beyond just halal and Sharia-compliance to also help investors align with their values and build long-term prosperity. Our team has decades of experience tailoring ethical investing strategies in the Saudi market. As we work toward an app, Rezq unlocks purpose-driven investing for Saudis. If you're ready to grow your wealth in line with what matters most, we deliver the knowledge and support you need.
              </p>
             
            </div>
            <div className="col-md-6 text-center">
              <div className="about-img-div">
                <div style={{ width: '100%', height: '100%' }}>
                  <img alt="About Rezq Capital" src="images/6.png" style={{ width: '90%' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-arrow-mobile-bottom hide-desktop">
          <img className="about-arrow-mobile" src="images/about-arrow-mobile.svg" alt="About Arrow" />
        </div>
      </section>

      <section className="about-video pt-100 pb-100 bg-gray text-center" id="about-us">
        <div className="container">
          <div className="main-title">
            <h1 className="about-v-title">Start investing today and take control of your future!</h1>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="video-thumbnail">
                <a
                  href="#play-video"
                  id="play-video"
                  onclick={(e) => {
                    e.preventDefault();
                    alert('Video will play here!');
                  }}
                >
                  <img className="video-play" alt="Play Video" src="images/icon-play.svg" />
                </a>
                <img className="about-v" alt="Video Thumbnail" src="images/1.jpeg" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="cta-content">
                <h4 className="cta-title">
                  Invest in Rezq Capital’s portfolios to secure your financial future
                </h4>
                <p className="cta-para">
                  Rezq Capital offers a range of investment portfolios designed to align with your financial goals
                  and risk profile. Choose from Conservative, Moderately Conservative, Moderately Aggressive, or
                  Aggressive portfolios.
                </p>
                <p className="cta-para">
                  With our secure platform and automated processes, we ensure your investments are managed
                  efficiently while adhering to Shariah-compliant principles.
                </p>
                <a
                  href="https://rezqcapital.sa/en/how-rezq-works#investment-wallets"
                  className="main-btn btn-blue"
                >
                  Discover More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="wallet-type d-none" id="investment-wallet">
        <div className="container">
          <div className="main-title text-center">
            <h4 className="wallet-title-s">Value Proposition</h4>
            <p className="wallet-title" id="investment-wallet--t" data-register-btn="Start Now">
              Rizq provides a mobile platform that offers customized halal portfolios, financial literacy courses,
              and access to expert advice. It integrates model portfolios inspired by successful billionaire
              investors like Warren Buffett, Ray Dalio, and Jack Bogle. Rizq is the only app offering both Islamic
              portfolio customization and proven billionaire asset allocation models, delivering an innovative and
              credible solution for sharia-compliant investing.
            </p>
           
          </div>
        </div>
      </section>

      <section className="why-invest" id="why-rezq">
        <div className="container">
          <div className="main-title text-center">
            <h1 className="why-invest-main-title" id="why-rezq--t">
              Market Fit &amp; Vision Alignment
            </h1>
            <p className="why-invest-para">
              Saudi Vision 2030: Rizq contributes to the Saudi Vision 2030 goals by promoting financial literacy,
              increasing savings rates, and offering accessible halal investment options.
            </p>
            <p className="why-invest-para">
              Underserved Niche: The platform targets a largely underserved market with mid to high-income earners,
              many of whom have limited knowledge of investment but a strong need for sharia-compliant options.
            </p>
          </div>
        </div>
      </section>

      <section className="invest pt-100 pb-100" id="start">
        <div className="container">
          <div className="row vcenter-item">
            <div className="col-md-6">
              <div className="invest-content">
                <h1 className="invest-title">Key Features</h1>
                <p className="invest-para">
                  Customized Halal Portfolios: Tailored to individual investment goals while fully compliant with
                  Islamic finance principles.
                </p>
                <p className="invest-para">
                  Financial Literacy: Education modules designed to improve financial knowledge, helping users make
                  informed investment decisions.
                </p>
                <p className="invest-para">
                  Expert Access: On-demand access to Islamic finance experts for personalized advice.
                </p>
                <p className="invest-para">
                  Model Portfolios: Portfolios based on strategies from legendary investors, enhancing credibility and
                  offering a proven path to wealth building.
                </p>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <img className="invest-img" alt="Invest Wisely Illustration" src="images/1.jpg" />
            </div>
          </div>
        </div>
      </section>

      <section className="security pb-100">
        <div className="container">
          <div className="row vcenter-item">
            <div className="col-md-6 ">
              <img className="security-img" alt="Security Illustration" src="images/2.jpg" />
            </div>
            <div className="col-md-6">
              <div className="security-content">
                <h1 className="security-title">Customer Segments</h1>
                <p className="security-para">
                  Primary: Young and mid-career professionals seeking halal investment options, especially those
                  attracted by billionaire model portfolios for their success and credibility.
                </p>
                <p className="security-para">
                  Secondary: Hands-off investors, women seeking female advisors, and retirees focusing on estate
                  planning.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>




















      <section class="faq pb-100 mt-5" id="faq">
  <div class="container">
    <div class="main-title text-center">
      <h1 class="faq-main-title" id="faq--t" data-register-btn="Sign Up">
        Frequently Asked Questions
        <img class="icon-faq" alt="FAQ Icon" src="images/icon-faq.png" />
      </h1>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="accordion" id="faqAccordion1">
          <div class="accordion-item">
            <h2 class="accordion-header" id="faqheading1">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqcollapse1" aria-expanded="false" aria-controls="faqcollapse1">
                What are the minimum amount to invest in the Rezq Platform?
              </button>
            </h2>
            <div id="faqcollapse1" class="accordion-collapse collapse" aria-labelledby="faqheading1" data-bs-parent="#faqAccordion1">
              <div class="accordion-body">
                The minimum investment amount for Rezq platform is 5,000 SAR.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="faqheading2">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqcollapse2" aria-expanded="false" aria-controls="faqcollapse2">
                Is Rezq's platform halal compliant?
              </button>
            </h2>
            <div id="faqcollapse2" class="accordion-collapse collapse" aria-labelledby="faqheading2" data-bs-parent="#faqAccordion1">
              <div class="accordion-body">
                Yes, Rezq platform is compliant with Islamic laws.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="faqheading3">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqcollapse3" aria-expanded="false" aria-controls="faqcollapse3">
                How do I track my investment?
              </button>
            </h2>
            <div id="faqcollapse3" class="accordion-collapse collapse" aria-labelledby="faqheading3" data-bs-parent="#faqAccordion1">
              <div class="accordion-body">
                You can track your investment status through your personalized dashboard, available 24/7 for you.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="accordion" id="faqAccordion2">
          <div class="accordion-item">
            <h2 class="accordion-header" id="faqheading4">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faqcollapse4" aria-expanded="false" aria-controls="faqcollapse4">
                How long does it take to withdraw funds?
              </button>
            </h2>
            <div id="faqcollapse4" class="accordion-collapse collapse" aria-labelledby="faqheading4" data-bs-parent="#faqAccordion2">
              <div class="accordion-body">
                Withdrawal requests are processed within 3 to 5 business days.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="faqheading5">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqcollapse5" aria-expanded="false" aria-controls="faqcollapse5">
                Can I invest in multiple portfolios?
              </button>
            </h2>
            <div id="faqcollapse5" class="accordion-collapse collapse" aria-labelledby="faqheading5" data-bs-parent="#faqAccordion2">
              <div class="accordion-body">
                Yes, you can invest in multiple portfolios based on your investment preferences.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="faqheading6">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqcollapse6" aria-expanded="false" aria-controls="faqcollapse6">
                Can I make changes to my portfolio after investing?
              </button>
            </h2>
            <div id="faqcollapse6" class="accordion-collapse collapse" aria-labelledby="faqheading6" data-bs-parent="#faqAccordion2">
              <div class="accordion-body">
                Yes, you can make adjustments to your portfolio at any time by logging into your dashboard.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>









    </div>










  );
};

export default Home;
