import React, { useEffect, useState } from 'react';
import Header from '../ComponentsAdmin/Header';
import Navbar from '../ComponentsAdmin/Navbar';
import Sidebar from '../ComponentsAdmin/Sidebar';

const Layout = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    const resources = [
      {
        href: 'https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700,900',
        rel: 'stylesheet',
      },
      {
        href: '/admin_assets/css/nucleo-icons.css',
        rel: 'stylesheet',
      },
      {
        href: '/admin_assets/css/nucleo-svg.css',
        rel: 'stylesheet',
      },
      {
        src: 'https://kit.fontawesome.com/42d5adcbca.js', // Correctly add as a script
        type: 'text/javascript',
      },
      {
        href: 'https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@24,400,0,0',
        rel: 'stylesheet',
      },
      {
        href: '/admin_assets/css/material-dashboard.css?v=3.2.0',
        rel: 'stylesheet',
      },
      {
        href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.2/css/all.min.css',
        rel: 'stylesheet',
      },
      {
        href: 'https://cdnjs.cloudflare.com/ajax/libs/sweetalert2/11.15.2/sweetalert2.css',
        rel: 'stylesheet',
      },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/sweetalert2/11.15.2/sweetalert2.min.js',
        type: 'text/javascript',
      },
      {
        src: '/admin_assets/js/core/popper.min.js',
        type: 'text/javascript',
      },
      {
        src: '/admin_assets/js/core/bootstrap.min.js',
        type: 'text/javascript',
      },
      {
        src: '/admin_assets/js/plugins/perfect-scrollbar.min.js',
        type: 'text/javascript',
      },
      {
        src: '/admin_assets/js/plugins/smooth-scrollbar.min.js',
        type: 'text/javascript',
      },
      {
        src: '/admin_assets/js/plugins/chartjs.min.js',
        type: 'text/javascript',
      },
      {
        src: 'https://buttons.github.io/buttons.js',
        type: 'text/javascript',
      },
     
      {
        src: '/admin_assets/js/main.js',
        type: 'text/javascript',
      },
    



    ];


    let loadedCount = 0;

    const handleLoad = () => {
      loadedCount += 1;
      if (loadedCount === resources.length) {
        setIsLoaded(true); // Mark as loaded once all resources are processed
      }
    };

    // Load resources
    resources.forEach(resource => {
      if (resource.href) {
        const linkElement = document.createElement('link');
        linkElement.rel = resource.rel;
        linkElement.href = resource.href;
        linkElement.onload = handleLoad;
        linkElement.onerror = handleLoad;
        document.head.appendChild(linkElement);
      } else if (resource.src) {
        const scriptElement = document.createElement('script');
        scriptElement.src = resource.src;
        scriptElement.type = resource.type || 'text/javascript';
        scriptElement.async = true;
        scriptElement.onload = handleLoad;
        scriptElement.onerror = handleLoad;
        document.body.appendChild(scriptElement);
      }
    });

    // Cleanup on unmount
    return () => {
      resources.forEach(resource => {
        if (resource.href) {
          const link = document.querySelector(`link[href="${resource.href}"]`);
          if (link) link.remove();
        } else if (resource.src) {
          const script = document.querySelector(`script[src="${resource.src}"]`);
          if (script) script.remove();
        }
      });
    };
  }, []);

  if (!isLoaded) {
    return (
      <div style={{ background: '#fff', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <div class="navbar-toggle d-flex" id="navbar-toggle" onClick={()=> {setMobileOpen(!mobileOpen)}}>☰</div>
      <Header />
      <Sidebar mobileOpen={mobileOpen}/>
      <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
        <Navbar />
        <div className="container-fluid py-2">{children}</div>
      </main>
    </div>
  );
};

export default Layout;
