import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation  } from 'react-router-dom';
import { postRequest } from '../axios_request';

const Home = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [user, setUser] = useState(null);  
  const navigate = useNavigate(); 
  const location = useLocation();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      navigate('/conservative'); 
    }
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('success') === 'true') {
      // alert('Success parameter found!');
      setSuccessMessage('Registration successful!');

    }


  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await postRequest('/login', {
        email: formData.email,
        password: formData.password,
      });
      if (response && response.user) {
        setUser(response.user);
        localStorage.setItem('user', JSON.stringify(response.user));
        setSuccessMessage(response.message);
        navigate('/conservative'); 
      } else {
        setErrorMessage('Login failed. Please check your credentials.');
      }
      setFormData({ email: '', password: '' });
    } catch (error) {
      console.error('Error:', error.response?.data || error.message);
      setErrorMessage(error.message || 'Something went wrong. Please try again.');
    }
  };
  return (
    <div>
      <div
        className="page-header align-items-start min-vh-100"
        style={{
          'min-height': '75vh !important',
          backgroundImage:
            "url('https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80')",
        }}
      >
        <span className="mask bg-gradient-dark opacity-6"></span>
        <div className="container my-auto">
          <div className="row">
            <div className="col-lg-4 col-md-8 col-12 mx-auto">
              <div className="card z-index-0 fadeIn3 fadeInBottom">
                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div className="bg-gradient-dark shadow-dark border-radius-lg py-3 pe-1 auth-header-img">
                    
                    <h4 className="text-white font-weight-bolder text-center mt-2 mb-0">Sign in</h4>
                  </div>
                </div>
                <div className="card-body">
                  {errorMessage && (
                    <div style={{ color: 'white' }} className="alert alert-danger">
                      {errorMessage}
                    </div>
                  )}
                  {successMessage && (
                    <div style={{ color: 'white' }} className="alert alert-success">
                      {successMessage}
                    </div>
                  )}
                  <form role="form" className="text-start" onSubmit={handleSubmit}>
                    <div className="input-group input-group-outline my-3">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="input-group input-group-outline mb-3">
                      <label className="form-label">Password</label>
                      <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                  
                    <div className="text-center">
                      <button type="submit" className="btn bg-gradient-dark w-100 my-4 mb-2">
                        Sign in
                      </button>
                    </div>
                    <p className="mt-4 text-sm text-center">
                      Don't have an account?{' '}
                      <Link className="text-primary text-gradient font-weight-bold" to="/signup">
                        Sign up
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
